import React from 'react';
import { useSelector } from 'react-redux';

import Header from './Header';
import MainSection from './MainSection';
import ErrorBoundary from '../components/ErrorBoundary';
import ReportTabsContainer from '../components/ReportTabsContainer';
import AdminAvalara from '../components/avalara/AdminAvalara';
import { getPopups } from '../selectors';
import Overlay from '../components/Overlay';
import { useIdentity } from '../hooks';
import { get } from 'lodash';
import config from '../config';

const AdminAvalaraApp = () => {
  const popups = useSelector(getPopups);
  const identity = useIdentity();

  return <ErrorBoundary>
    <Header></Header>
    <MainSection popups={popups}>
      <div className="main-content" style={{ paddingRight: '12px' }}>
        <ReportTabsContainer page="admin_avalara" csku={false} />
        <div className="row full-width" style={{ paddingLeft: '1.25rem' }}>
          {
            ((get(config, 'avalara.beta_companies') || [identity.company_id]).indexOf(identity.company_id) > -1) &&
            <AdminAvalara/>
          }
        </div>
      </div>
    </MainSection>
    <Overlay popups={popups} />
  </ErrorBoundary>;
};

export default AdminAvalaraApp;
